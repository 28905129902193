<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="请假学生">
              <a-input allow-clear v-model="form.studentName" placeholder="姓名或学号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="请假状态">
              <a-select allow-clear v-model="form.approvalStatus" placeholder="请选择">
                <a-select-option v-for="item in scheduleOptions.leaveStatus" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班型">
              <a-select allow-clear v-model="form.classType" placeholder="请选择">
                <a-select-option v-for="item in scheduleOptions.classType" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="申请日期">
              <a-range-picker
                style="width: 100%"
                @change="onChangeApplyDate"
                v-model="applyTimeArr"
                allowClear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="开课日期">
              <a-range-picker
                style="width: 100%"
                @change="onChangeLeaveDate"
                v-model="leaveDateArr"
                allowClear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="userInfo.leader">
            <a-form-item label="组织(部门):">
              <a-tree-select
                allow-clear
                v-model="form.deptId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                placeholder="请选择"
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="userInfo.leader">
            <a-form-item label="班主任">
              <a-select
                allow-clear
                show-search
                placeholder="请输入"
                v-model="form.ownerId"
                @change="transformClassAdminIds"
                @search="loadClassAdminOptions"
                :filter-option="filterOption"
                option-filter-prop="children"
                :dropdownMatchSelectWidth="false"
              >
                <a-select-option v-for="item in classAdminList" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" style="margin-right: 10px" @click="getListData(1)">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetFormLeave">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="stable-wrap">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 2000 }"
      >
        <span slot="studentName" slot-scope="text, record">
          <a @click="toStudentDetail(record)">{{ text }}（{{ record.studentRespDTO.code }}）</a>
        </span>
        <span slot="approvalStatus" slot-scope="text">{{ getDdlLabel(scheduleOptions.leaveStatus, text) }}</span>
        <span slot="scheduleName" slot-scope="text, record">
          <router-link
            :to="{ path: '/workoutSchedule/scheduleDetail', query: { id: record.courseScheduleRespDTO.uuid } }"
          >
            {{ text }}</router-link
          >
        </span>
        <span slot="classType" slot-scope="text">{{ getDdlLabel(scheduleOptions.classType, text) }}</span>
        <span slot="acceptReschedule" slot-scope="text">{{ text ? '是' : '否' }}</span>
        <span slot="applicant" slot-scope="text, record">
          <span v-if="record.data.isStudent">
            {{ `${record.studentRespDTO.fullName}(${record.studentRespDTO.code})` }}
          </span>
          <span v-else>
            {{ extractOperator(text) }}
          </span>
        </span>
        <span slot="operator" slot-scope="text, record">
          <span v-if="record.data.isStudent">
            {{ `${record.studentRespDTO.fullName}(${record.studentRespDTO.code})` }}
          </span>
          <span v-else>
            {{ extractOperator(text) }}
          </span>
        </span>
        <span slot="approvalRemarks" slot-scope="text, record">
          <router-link
            v-if="record.approvalStatus === 'PASSED' && record.classType === 'GROUP' && !!record.data.newCourseSchedule"
            :to="{ path: '/workoutSchedule/scheduleDetail', query: { id: record.data.newCourseSchedule } }"
          >
            {{ text }}</router-link
          >
          <span v-else>{{ text }}</span>
        </span>
        <span slot="action" slot-scope="text, record" style="word-break: keep-all">
          <span
            v-if="
              record.approvalStatus === 'NORMAL' &&
              record.classType === 'GROUP' &&
              record.courseScheduleRespDTO.courseSectionRespDTO.course.courseType === 'STANDARD'
            "
          >
            <router-link
              :to="{
                path: '/workoutSchedule/adjustScheduleList',
                query: {
                  studentTimezone: record.studentRespDTO.timezoneStd,
                  courseScheduleId: record.courseScheduleRespDTO.uuid,
                  studentId: record.studentId,
                  leaveItemId: record.uuid,
                },
              }"
            >
              调课
            </router-link>
            <a @click="cancelReschedule(record.uuid)"> 放弃 </a>
          </span>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import storage from 'store';
import { postReq, loadClassAdminOptions } from '@/api/schedule';
import { getDdlLabel, extractOperator } from '@/utils/util';
import BaseList from '@/components/BaseList/index.vue';
import { getTreeList } from '@/api/headTeacher';

const columns = [
  {
    title: '请假学生',
    dataIndex: 'studentName',
    key: 'studentName',
    fixed: 'left',
    width: 140,
    scopedSlots: { customRender: 'studentName' },
  },
  {
    title: '处理状态',
    dataIndex: 'approvalStatus',
    key: 'approvalStatus',
    fixed: 'left',
    width: 80,
    scopedSlots: { customRender: 'approvalStatus' },
  },
  {
    title: '请假课节',
    dataIndex: 'scheduleName',
    key: 'scheduleName',
    scopedSlots: { customRender: 'scheduleName' },
  },
  {
    title: '班主任',
    key: 'headmasterName',
    dataIndex: 'headmasterName',
  },
  {
    title: '开课日期',
    key: 'leaveDate',
    dataIndex: 'leaveDate',
  },
  {
    title: '班型',
    key: 'classType',
    dataIndex: 'classType',
    scopedSlots: { customRender: 'classType' },
  },
  {
    title: '请假原因',
    key: 'reason',
    dataIndex: 'reason',
  },
  {
    title: '是否调课',
    key: 'acceptReschedule',
    dataIndex: 'acceptReschedule',
    scopedSlots: { customRender: 'acceptReschedule' },
  },
  {
    title: '申请人',
    key: 'data.applicant',
    dataIndex: 'data.applicant',
    scopedSlots: { customRender: 'applicant' },
  },
  {
    title: '申请时间',
    key: 'applyTime',
    dataIndex: 'applyTime',
  },
  {
    title: '处理人',
    key: 'data.operator',
    dataIndex: 'data.operator',
    scopedSlots: { customRender: 'operator' },
  },
  {
    title: '处理时间',
    key: 'processTime',
    dataIndex: 'processTime',
  },
  {
    title: '授课老师',
    key: 'teacherName',
    dataIndex: 'teacherName',
  },

  {
    title: '处理结果',
    key: 'approvalRemarks',
    fixed: 'right',
    width: 200,
    dataIndex: 'approvalRemarks',
    scopedSlots: { customRender: 'approvalRemarks' },
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: 80,
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  extends: BaseList,
  data() {
    return {
      userInfo: {},
      applyTimeArr: [],
      leaveDateArr: [],
      treeData: [],
      applyTimeStart: null,
      applyTimeEnd: null,
      leaveDateStart: null,
      leaveDateEnd: null,
      getDdlLabel,
      extractOperator,
      scheduleOptions: {
        classType: [],
        courseType: [],
        classStatus: [],
        leaveStatus: [],
        formClassCapacityStatus: [],
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      columns,
      searchUrl: `/api/admin/scheduling/leave`,
      form: {
        studentName: undefined,
        approvalStatus: undefined,
        ownerId: undefined,
        deptId: undefined,
        applyTimeStart: null,
        applyTimeEnd: null,
        leaveDateStart: null,
        leaveDateEnd: null,
        classAdminIds: [],
      },
      classAdminList: [],
    };
  },
  created() {
    this.userInfo = storage.get('userInfo');
    this.getTreeList();
  },
  methods: {
    transformClassAdminIds(e) {
      this.form.classAdminIds = e ? [e] : undefined;
    },
    resetFormLeave() {
      this.form = {
        studentName: undefined,
        approvalStatus: undefined,
        ownerId: undefined,
        deptId: undefined,
        applyTimeStart: null,
        applyTimeEnd: null,
        leaveDateStart: null,
        leaveDateEnd: null,
      };
      this.applyTimeArr = [];
      this.leaveDateArr = [];
      this.getListData(1);
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    onChangeApplyDate(date) {
      if (date.length > 0) {
        this.form.applyTimeStart = date[0].format('YYYY-MM-DD');
        this.form.applyTimeEnd = date[1].format('YYYY-MM-DD');
      } else {
        this.form.applyTimeStart = null;
        this.form.applyTimeEnd = null;
      }
    },
    onChangeLeaveDate(date) {
      if (date.length > 0) {
        this.form.leaveDateStart = date[0].format('YYYY-MM-DD');
        this.form.leaveDateEnd = date[1].format('YYYY-MM-DD');
      } else {
        this.form.leaveDateStart = null;
        this.form.leaveDateEnd = null;
      }
    },
    loadClassAdminOptions(name) {
      const params = {
        name,
      };
      loadClassAdminOptions(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    cancelReschedule(id) {
      const $this = this;
      this.$confirmAnt({
        title: '放弃调课',
        content: `确认要 放弃调课 吗`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          $this.loading = true;
          postReq(`/api/admin/scheduling/leave/adjust/give_up/${id}`, {})
            .then(() => {
              $this.getListData();
            })
            .finally(() => {
              $this.loading = false;
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    toStudentDetail(record) {
      this.$router.push({
        path: '/studentDetail',
        query: {
          uuid: record.studentId,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.ant-form-item {
  margin-bottom: 0px;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0px 20px 20px;
  display: flex;
  justify-content: space-between;
}

.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}
.stable-wrap {
  background-color: #fff;
  padding: 16px 20px;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-bottom: 14px;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
</style>
